import { useRef } from "react";
import mobileImg from "../assets/imgs/mobilesImg.webp";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { FaGooglePlay } from "react-icons/fa";
import { GrApple } from "react-icons/gr";
import { toast } from "react-toastify";

const Banner = () => {
  const contentRef1 = useRef<HTMLDivElement>(null);

  const triggerRef3 = useRef<HTMLDivElement>(null);
  const iconRef1 = useRef<HTMLDivElement>(null);
  const iconRef2 = useRef<HTMLDivElement>(null);
  const triggerIconRef = useRef<HTMLDivElement>(null);

  useGSAP(() => {
    gsap.from(contentRef1.current, {
      x: 450,
      opacity: 0,
      duration: 1.8,
      scrollTrigger: {
        trigger: triggerRef3.current,
        scroller: "body",
        start: "top 70%",
      },
    });
    gsap.from(iconRef1.current, {
      x: -200,
      duration: 1.8,
      opacity: 0,
      scrollTrigger: {
        trigger: triggerIconRef.current,
        scroller: "body",
        start: "top 70%",
      },
    });
    gsap.from(iconRef2.current, {
      x: 200,
      duration: 1.8,
      opacity: 0,
      scrollTrigger: {
        trigger: triggerIconRef.current,
        scroller: "body",
        start: "top 70%",
      },
    });
  });

  gsap.registerPlugin(ScrollTrigger);

  const handleIosClick = () => {
    toast.warn(
      <div>
        <strong>Coming soon!</strong>
        {/* <div>
          The iOS version of our app will be available shortly. Stay tuned!
        </div> */}
        <div>
          Our app will be available shortly. Stay tuned!
        </div>
      </div>
    );
  };

  return (
    <div className="min-h-screen pt-7 overflow-hidden flex flex-col items-center justify-center bg-[#F0F8FF] pb-[5rem] md:pb-[10rem]">
      {/* <div
        className="text-center pt-[5rem] md:pt-[9rem] px-3"
        ref={textRef}
      >
        <h1 className="text-[#030A74] lg:text-[1.9rem] text-[1rem] font-bold md:text-[1.6rem]">
          Early Access to Our AI-Powered Xampus App is Here!
        </h1>
        <p className="text-[#030A74] tracking-tight font-semibold leading-[2rem] md:leading-[3rem] text-[14px] md:text-[22px] lg:text-[24px] mt-[1rem] max-w-[56rem] mx-auto">
          To get early access, email us at{" "}
          <span className="text-[#3275C3] font-bold underline cursor-pointer">
            earlyaccess@xampus.in
          </span>{" "}
          and receive your invite link within one hour.
        </p>
      </div> */}

      <div className="w-[92%] min-h-[50rem] md:min-h-[70rem] lg:min-h-[85vh] grid grid-cols-1 lg:grid-cols-2 bg-custom-gradient rounded-[2rem] space-y-7">
        <div className="relative flex justify-center items-center">
          <img
            src={mobileImg}
            alt="Mobile"
            className="absolute top-5 h-[24rem] md:h-[34rem] lg:h-[46rem]"
          />
        </div>

        <div
          className="flex flex-col items-center gap-y-14 justify-center text-center text-white px-4 h-full"
          ref={triggerRef3}
        >
          <div ref={contentRef1}>
            <h2 className="font-bold text-[1.2rem] md:text-[2.4rem] lg:text-[2.6rem]">
              Checkout our Xampus App
            </h2>
            <div className="flex flex-col items-center justify-center gap-y-8">
              <p className="text-[1rem] md:text-[1.6rem] leading-7 sm:leading-8 md:leading-10 w-[90%] sm:w-[78%]">
                Connect, collaborate, and cultivate your career with like-minded
                professionals across diverse industries.
              </p>
            </div>
          </div>

          <div
            className="flex gap-2 md:gap-6 flex-wrap justify-center"
            ref={triggerIconRef}
          >
            <div
              className="flex items-center gap-2 md:gap-4 px-3 py-2 md:px-6 md:py-4 text-[0.8rem] md:text-[1rem] font-semibold cursor-pointer text-black bg-[#fff] border border-black rounded-xl md:rounded-2xl"
              ref={iconRef1}
              onClick={handleIosClick}
            >
              <GrApple className="text-[1.5rem] md:text-[2.6rem]" />
              <p className="text-lg md:text-2xl font-normal">App Store</p>
            </div>
            {/* <a
              href="#"
              target="blank"
            > */}
              <div
                className="flex items-center gap-2 md:gap-4 px-3 py-2 md:px-6 md:py-4 text-[0.8rem] md:text-[1rem] text-[#fff] font-semibold cursor-pointer bg-[#060F40] rounded-xl md:rounded-2xl"
                ref={iconRef2}
                onClick={handleIosClick}
              >
                <FaGooglePlay className="text-[1.5rem] md:text-[2.5rem]" />
                <p className="text-lg md:text-2xl font-normal">Google Play</p>
              </div>
            {/* </a> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
