import React from "react";

type StepperProps = {
  currentStep: number;
  steps: string[];
};

const progress = [
  "Job Details",
  "Applicant Qualifications",
  "Preview",
  "Post Job",
];

const ProgressIndicator: React.FC<StepperProps> = ({ currentStep, steps }) => {
  return (
    <div className="min-h-screen flex flex-col items-center w-full bg-[#24336B] space-y-[4rem] overflow-hidden">
      <div className="w-[85%]">
        <div className="flex w-full">
          <div className="flex flex-col items-center">
            {steps.map((step, index) => (
              <div key={index} className="flex flex-col items-center">
                <div
                  className={`flex items-center justify-center w-8 h-8 rounded-full ${
                    index + 1 <= currentStep
                      ? "bg-[#208EFB] text-white"
                      : "bg-[#D9D9D9] text-gray-500"
                  }`}
                >
                  {index + 1 < currentStep ? "✓" : index + 1}
                </div>
                {index < steps.length - 1 && (
                  <div
                    className={`w-[2px] h-[6rem] ${
                      index + 1 <= currentStep ? "bg-[#208EFB]" : "bg-[#D9D9D9]"
                    }`}
                  ></div>
                )}
              </div>
            ))}
          </div>
          <div className="flex flex-col justify-between ml-5">
            {progress.map((step, index) => (
              <p
                key={index}
                className="text-[#fff] md:text-[14px] lg:text-[24px] font-medium"
              >
                {step}
              </p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProgressIndicator;
