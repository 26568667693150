import React from 'react'

interface Props{
    title: string
    paragraph: string
    logo: string
}

const Card = ({title, paragraph, logo}: Props) => {

    return (
        <div className="max-w-[30rem] md:max-w-[35rem] mx-auto bg-white text-[#0A1132] shadow-xl rounded-xl flex flex-col md:flex-row items-center p-6 md:p-[3rem]">
            <div className="flex-shrink-0 flex items-center justify-center w-24 h-24 bg-[#D5DBF0] rounded-full mb-4 md:mb-0">
                <img src={logo} alt="Logo" className="h-12 w-12 object-contain" />
            </div>
            <div className="text-left md:ml-6">
                <h2 className="text-center md:text-left text-[1.8rem] font-bold">{title}</h2>
                <p className="mt-2 text-gray-600">{paragraph}</p>
            </div>
        </div>
    );
}

export default Card
