import {Routes, Route} from 'react-router-dom';
import './App.css';
import Privacy_Policy from './pages/Privacy-Policy';
import Terms from './pages/TermsOfService';
import Home from './pages/Home';
// import MembersLogin from './pages/MembersLogin';
import NotFound from './pages/NotFound';
import Login from './pages/Login';
import Register from './pages/Register';
// import PostForm from './pages/AddPost';
import AccountDeletion from './pages/AccountDeletion';
import XampusId from './pages/XampusId';
import AboutUs from './pages/About-us';
import About from './pages/About';
import JobHunt from './pages/JobHunt';
import HrSignIn from './pages/HR-Auth-Pages/SignIn/HR-Auth';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import AddPost from './pages/AddPost/AddPost';
import AddPostDetails from './pages/HR-Add-JobDetails/HR-Add-Post-Details';
import HrHomepage from './pages/hr-Portal-Homepage/Homepage';
import Payment from './components/hr-portal/payment/Payment';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/termsofservice' element={<Terms/>}/>
        <Route path='/privacypolicy' element={<Privacy_Policy/>}/>
        <Route path='/about-us' element={<AboutUs/>}/>
        <Route path='/about' element={<About/>}/>
        <Route path='/xampusid' element={<XampusId/>}/>
        <Route path='/user/login' element={<Login/>}/>
        <Route path='/user/register' element={<Register/>}/>
        <Route path='/jobhunt' element={<JobHunt/>}/>
        <Route path='/auth/hr-portal' element={<HrSignIn/>}/>
        <Route path='/auth/hr-portal/forgot-password' element={<ForgotPassword/>}/>
        <Route path='/hr-portal/add-post' element={<AddPost/>}/>
        <Route path='/hr-portal/add-post-details' element={<AddPostDetails/>}/>
        <Route path='/hr-portal/payment' element={<Payment/>}/>
        <Route path='/hr-portal/homepage' element={<HrHomepage/>}/>
        {/* <Route path='/members/login' element={<MembersLogin/>}/>
        <Route path='/add' element={<PostForm/>}/>*/}
        <Route path='/account-deletion' element={<AccountDeletion/>}/> 
        <Route path='*' element={<NotFound/>}/>
      </Routes>
    </div>
  );
}

export default App;
