import React, { useState } from "react";
import Navbar from "../components/Navbar";
import {toast} from 'react-toastify'
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/firebaseContext/Context";

const Register = () => {
  const navigate = useNavigate()

  const {userRegister} = useAuth()

  const [user, setUser] = useState({
    fullname: "",
    email: "",
    password: "",
    confirmPassword: "",
    gender: "",
  });

  const [errors, setErrors] = useState({
    fullname: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [loading, setLoading] = useState<boolean>(false);

  const handleInput = (e: any) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
    
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const newErrors = {
      fullname: user.fullname ? "" : "Fullname is required",
      email: user.email ? "" : "Email is required",
      password: user.password ? "" : "Password is required",
      confirmPassword: user.confirmPassword ? "" : "Confirm Password is required",
    };


    if (newErrors.fullname || newErrors.email || newErrors.password) {
      setErrors(newErrors);
    } else {
      
    if (user.password !== user.confirmPassword) {
      setErrors({
        ...errors,
        confirmPassword: "Password does not match"
      });
      return;
    }
    
      try {
        setLoading(true);
        await userRegister(user.email, user.password, user.fullname);
        navigate('/')
        // If login is successful, we might want to redirect or perform other actions here
      } catch (error: any) {
        console.error("Error", error.message);
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    }
  };


  return (
    <>
      <Navbar styles="bg-[#071143]" />
      <section className="bg-gray-100">
        <div className="flex flex-col items-center justify-center mx-auto min-h-screen lg:py-0">
          <div className="bg-white rounded-lg shadow dark:border w-full max-w-md">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">

              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
                Create an account
              </h1>

              <form onSubmit={handleSubmit} className="space-y-4 md:space-y-6">
                <div>
                  <label
                    className="block mb-2 text-sm font-medium text-gray-900"
                    htmlFor="name"
                  >
                    Full Name
                  </label>
                  <input
                    type="text"
                    name="fullname"
                    placeholder="John Doe"
                    value={user.fullname}
                    onChange={handleInput}
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-green-600 focus:border-sky-400 block w-full p-2.5"
                  />
                  {errors.fullname && <p className='text-sm text-red-600'>{errors.fullname}</p>}
                </div>

                <div>
                  <label
                    className="block mb-2 text-sm font-medium text-gray-900"
                    htmlFor="email"
                  >
                    Your Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    placeholder="name@example.com"
                    value={user.email}
                    onChange={handleInput}
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-green-600 focus:border-sky-400 block w-full p-2.5"
                  />
                  {errors.email && <p className='text-sm text-red-600'>{errors.email}</p>}
                </div>

                <div>
                  <label
                    className="block mb-2 text-sm font-medium text-gray-900"
                    htmlFor="password"
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    name="password"
                    placeholder="••••••••"
                    value={user.password}
                    onChange={handleInput}
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-green-600 focus:border-sky-400 block w-full p-2.5"
                  />
                  {errors.password && <p className='text-sm text-red-600'>{errors.password}</p>}
                </div>

                <div>
                  <label
                    className="block mb-2 text-sm font-medium text-gray-900"
                    htmlFor="password"
                  >
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    name="confirmPassword"
                    placeholder="••••••••"
                    value={user.confirmPassword}
                    onChange={handleInput}
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-green-600 focus:border-sky-400 block w-full p-2.5"
                  />
                  {errors.confirmPassword && <p className='text-sm text-red-600'>{errors.confirmPassword}</p>}
                </div>

                <div className="flex items-center mb-4 gap-2">
                    <input
                      type="radio"
                      value="Male"
                      onChange={handleInput}
                      name="gender"
                      required
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                    />
                    <label
                      className="text-sm font-medium text-gray-900"
                    >
                      Male
                    </label>

                    <input
                      type="radio"
                      value="Female"
                      onChange={handleInput}
                      name="gender"
                      required
                      className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300"
                    />
                    <label
                      className="text-sm font-medium text-gray-900"
                    >
                      Female
                    </label>
                </div>

                <button
                  type="submit"
                  className="w-full text-white bg-green-500 hover:bg-green-700 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center0"
                >
                  {loading ? "Registering..." : "Register"}
                </button>
                <p className="text-sm font-light text-gray-500">
                  Already have an account?{" "}
                  <Link
                    to="/user/login"
                    className="font-medium text-indigo-600 hover:underline"
                  >
                    Login
                  </Link>
                </p>

              </form>
            </div>
          </div>
        </div>
      </section>

    </>
  );
};

export default Register;
