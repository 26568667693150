import React from 'react';
import { useAuth } from '../contexts/firebaseContext/Context';
import { useNavigate } from 'react-router-dom';

interface LogoutModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const LogoutModal = ({ isOpen, onClose }: LogoutModalProps) => {
  const { userLogout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await userLogout();
      navigate('/');
      onClose(); // Close the modal after logout
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed z-50 inset-0 bg-gray-600 bg-opacity-75 flex justify-center items-center">
      <div className="bg-white p-6 rounded-lg shadow-lg text-center">
        <h2 className="text-xl font-semibold mb-4">Are you sure you want to log out?</h2>
        <button
          onClick={handleLogout}
          className="bg-red-500 text-white py-2 px-4 rounded-lg mr-2 hover:bg-red-600"
        >
          Logout
        </button>
        <button
          onClick={onClose}
          className="bg-gray-300 text-black py-2 px-4 rounded-lg hover:bg-gray-400"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default LogoutModal;
