export const DisposableEmailDomains = [
  "0-mail.com",
  "0815.ru",
  "0clickemail.com",
  "0wnd.net",
  "0wnd.org",
  "10mail.org",
  "10minutemail.com",
  "20mail.it",
  "20mail.eu",
  "2prong.com",
  "30minutemail.com",
  "33mail.com",
  "3d-painting.com",
  "4warding.com",
  "4warding.net",
  "4warding.org",
  "60minutemail.com",
  "675hosting.com",
  "675hosting.net",
  "675hosting.org",
  "6url.com",
  "75hosting.com",
  "75hosting.net",
  "75hosting.org",
  "99experts.com",
  "a-bc.net",
  "afrobacon.com",
  "ajaxapp.net",
  "amilegit.com",
  "anonbox.net",
  "anonymail.dk",
  "anonymbox.com",
  "antichef.com",
  "antichef.net",
  "antispam.de",
  "baxomale.ht.cx",
  "beefmilk.com",
  "binkmail.com",
  "bio-muesli.net",
  "bobmail.info",
  "bodhi.lawlita.com",
  "bofthew.com",
  "boun.cr",
  "bouncr.com",
  "breakthru.com",
  "brefmail.com",
  "bsnow.net",
  "bugmenot.com",
  "bumpymail.com",
  "casualdx.com",
  "centermail.com",
  "centermail.net",
  "chammy.info",
  "childsavetrust.org",
  "chogmail.com",
  "choicemail1.com",
  "cool.fr.nf",
  "correo.blogos.net",
  "cosmorph.com",
  "courriel.fr.nf",
  "courrieltemporaire.com",
  "crapmail.org",
  "cubiclink.com",
  "curryworld.de",
  "cust.in",
  "dacoolest.com",
  "dandikmail.com",
  "deadaddress.com",
  "deadspam.com",
  "despam.it",
  "despammed.com",
  "devnullmail.com",
  "dfgh.net",
  "digitalsanctuary.com",
  "discardmail.com",
  "discardmail.de",
  "disposableaddress.com",
  "disposableemailaddresses.com",
  "disposableinbox.com",
  "dispose.it",
  "dispostable.com",
  "dm.w3internet.co.uk",
  "dodgeit.com",
  "dodgit.com",
  "dodgit.org",
  "dontreg.com",
  "dontsendmespam.de",
  "dump-email.info",
  "dumpandjunk.com",
  "dumpmail.de",
  "dumpyemail.com",
  "e4ward.com",
  "easytrashmail.com",
  "email60.com",
  "emailigo.de",
  "emailinfive.com",
  "emailmiser.com",
  "emailsensei.com",
  "emailtemporanea.net",
  "emailtemporar.ro",
  "emailtemporario.com.br",
  "emailthe.net",
  "emailwarden.com",
  "emailx.at.hm",
  "emailxfer.com",
  "emeil.in",
  "emeil.ir",
  "emz.net",
  "ero-tube.org",
  "etranquil.com",
  "etranquil.net",
  "etranquil.org",
  "evopo.com",
  "explodemail.com",
  "express.net.ua",
  "eyepaste.com",
  "fakeinbox.com",
  "fakeinformation.com",
  "fansworldwide.de",
  "fantasymail.de",
  "fightallspam.com",
  "filzmail.com",
  "fizmail.com",
  "fleckens.hu",
  "fr33mail.info",
  "frapmail.com",
  "freundin.ru",
  "front14.org",
  "fuckingduh.com",
  "fudgerub.com",
  "fux0ringduh.com",
  "garliclife.com",
  "gehensiemirnichtaufdensack.de",
  "get1mail.com",
  "get2mail.fr",
  "getairmail.com",
  "getmails.eu",
  "getonemail.com",
  "getonemail.net",
  "girlsundertheinfluence.com",
  "gishpuppy.com",
  "goemailgo.com",
  "gotmail.net",
  "gotmail.org",
  "gowikibooks.com",
  "gowikicampus.com",
  "gowikicars.com",
  "gowikifilms.com",
  "gowikigames.com",
  "gowikimusic.com",
  "gowikinetwork.com",
  "gowikitravel.com",
  "gowikitv.com",
  "great-host.in",
  "greensloth.com",
  "grr.la",
  "gsrv.co.uk",
  "guerillamail.biz",
  "guerillamail.com",
  "guerillamail.net",
  "guerillamail.org",
  "guerrillamail.biz",
  "guerrillamail.com",
  "guerrillamail.de",
  "guerrillamail.net",
  "guerrillamail.org",
  "guerrillamailblock.com",
  "gustr.com",
  "h.mintemail.com",
  "haltospam.com",
  "harakirimail.com",
  "hat-geld.de",
  "hatespam.org",
  "herp.in",
  "hidemail.de",
  "hidzz.com",
  "hmamail.com",
  "hochsitze.com",
  "hotpop.com",
  "hulapla.de",
  "ieatspam.eu",
  "ieatspam.info",
  "ieh-mail.de",
  "ihateyoualot.info",
  "iheartspam.org",
  "imails.info",
  "inbax.tk",
  "inbox.si",
  "inboxalias.com",
  "inboxclean.com",
  "inboxclean.org",
  "incognitomail.com",
  "incognitomail.net",
  "incognitomail.org",
  "insorg-mail.info",
  "instant-mail.de",
  "ipoo.org",
  "irish2me.com",
  "iwi.net",
  "jetable.com",
  "jetable.fr.nf",
  "jetable.net",
  "jetable.org",
  "jnxjn.com",
  "jourrapide.com",
  "jsrsolutions.com",
  "kasmail.com",
  "kaspop.com",
  "keepmymail.com",
  "killmail.com",
  "killmail.net",
  "klassmaster.com",
  "klassmaster.net",
  "klzlk.com",
  "koszmail.pl",
  "kurzepost.de",
  "letthemeatspam.com",
  "lhsdv.com",
  "lifebyfood.com",
  "link2mail.net",
  "litedrop.com",
  "lol.ovpn.to",
  "lookugly.com",
  "lortemail.dk",
  "lr78.com",
  "lroid.com",
  "lukop.dk",
  "maboard.com",
  "mail-filter.com",
  "mail-temporaire.fr",
  "mail.by",
  "mail.mezimages.net",
  "mail114.net",
  "mail15.com",
  "mail2rss.org",
  "mail333.com",
  "mail4trash.com",
  "mailbidon.com",
  "mailbiz.biz",
  "mailblocks.com",
  "mailbucket.org",
  "mailcat.biz",
  "mailcatch.com",
  "mailde.de",
  "mailde.info",
  "maildrop.cc",
  "maildx.com",
  "maileater.com",
  "mailexpire.com",
  "mailfa.tk",
  "mailforspam.com",
  "mailfreeonline.com",
  "mailguard.me",
  "mailin8r.com",
  "mailinater.com",
  "mailinator.com",
  "mailinator.net",
  "mailinator.org",
  "mailinator2.com",
  "mailincubator.com",
  "mailismagic.com",
  "mailme.lv",
  "mailme24.com",
  "mailmetrash.com",
  "mailmoat.com",
  "mailnator.com",
  "mailnesia.com",
  "mailnull.com",
  "mailorg.org",
  "mailpick.biz",
  "mailrock.biz",
  "mailscrap.com",
  "mailseal.de",
  "mailshell.com",
  "mailsiphon.com",
  "mailslapping.com",
  "mailslite.com",
  "mailtemp.info",
  "mailto.plus",
  "mailtome.de",
  "mailtothis.com",
  "mailtrash.net",
  "mailtv.net",
  "mailtv.tv",
  "mailzi.ru",
  "makemetheking.com",
  "manybrain.com",
  "mbx.cc",
  "mega.zik.dj",
  "meinspamschutz.de",
  "meltmail.com",
  "messagebeamer.de",
  "mezimages.net",
  "ministry-of-silly-walks.de",
  "mintemail.com",
  "misterpinball.de",
  "mohmal.com",
  "moncourrier.fr.nf",
  "monemail.fr.nf",
  "monmail.fr.nf",
  "mt2009.com",
  "mt2014.com",
  "mx0.wwwnew.eu",
  "mycleaninbox.net",
  "mypartyclip.de",
  "myphantomemail.com",
  "myspaceinc.com",
  "myspaceinc.net",
  "myspaceinc.org",
  "myspacepimpedup.com",
  "myspamless.com",
  "mytempemail.com",
  "mytempmail.com",
  "mytrashmail.com",
  "nabuma.com",
  "neomailbox.com",
  "nepwk.com",
  "nervmich.net",
  "nervtmich.net",
  "netmails.com",
  "netmails.net",
  "netzidiot.de",
  "neverbox.com",
  "nice-4u.com",
  "nincsmail.hu",
  "nnh.com",
  "no-spam.ws",
  "nobulk.com",
  "nobuma.com",
  "noclickemail.com",
  "nomail.pw",
  "nomail.xl.cx",
  "nomail2me.com",
  "nomorespamemails.com",
  "nonspammer.de",
  "nospam.ze.tc",
  "nospam4.us",
  "nospamfor.us",
  "nospammail.net",
  "nospamthanks.info",
  "notmailinator.com",
  "notsharingmy.info",
  "nowhere.org",
  "nowmymail.com",
  "ntlhelp.net",
  "nullbox.info",
  "objectmail.com",
  "obobbo.com",
  "odaymail.com",
  "onewaymail.com",
  "online.ms",
  "oopi.org",
  "opayq.com",
  "ordinaryamerican.net",
  "otherinbox.com",
  "ourklips.com",
  "outlawspam.com",
  "ovpn.to",
  "owlpic.com",
  "pancakemail.com",
  "pimpedupmyspace.com",
  "pjjkp.com",
  "plexolan.de",
  "poczta.onet.pl",
  "politikerclub.de",
  "poofy.org",
  "pookmail.com",
  "postacin.com",
  "postfach.cc",
  "postonline.me",
  "privacy.net",
  "privatdemail.net",
  "privy-mail.com",
  "proxymail.eu",
  "prtnx.com",
  "putthisinyourspamdatabase.com",
  "qq.com",
  "quickinbox.com",
  "quickmail.nl",
  "rcpt.at",
  "reallymymail.com",
  "realtyalerts.ca",
  "recode.me",
  "recursor.net",
  "reliable-mail.com",
  "rhyta.com",
  "rmqkr.net",
  "royal.net",
  "rppkn.com",
  "rtrtr.com",
  "s0ny.net",
  "safe-mail.net",
  "safersignup.de",
  "safetymail.info",
  "safetypost.de",
  "sandelf.de",
  "saynotospams.com",
  "schafmail.de",
  "schrott-email.de",
  "secretemail.de",
  "secure-mail.biz",
  "secure-mail.cc",
  "selfdestructingmail.com",
  "send-email.org",
  "sendspamhere.com",
  "server.ms",
  "services391.com",
  "sharklasers.com",
  "shieldedmail.com",
  "shieldemail.com",
  "shiftmail.com",
  "shitmail.me",
  "shitware.nl",
  "shortmail.net",
  "sibmail.com",
  "sinnlos-mail.de",
  "slapsfromlastnight.com",
  "slaskpost.se",
  "slipry.net",
  "slopsbox.com",
  "smashmail.de",
  "smellfear.com",
  "snakemail.com",
  "sneakemail.com",
  "sneakmail.de",
  "snkmail.com",
  "sofimail.com",
  "sofort-mail.de",
  "sogetthis.com",
  "soodonims.com",
  "spam.su",
  "spam4.me",
  "spamail.de",
  "spamarrest.com",
  "spamavert.com",
  "spambob.com",
  "spambob.net",
  "spambob.org",
  "spambog.com",
  "spambog.de",
  "spambog.net",
  "spambog.ru",
  "spambooger.com",
  "spambox.info",
  "spambox.us",
  "spamcannon.com",
  "spamcannon.net",
  "spamcero.com",
  "spamcon.org",
  "spamcorptastic.com",
  "spamcowboy.com",
  "spamcowboy.net",
  "spamcowboy.org",
  "spamday.com",
  "spamdecoy.net",
  "spamdoze.com",
  "spamfree.eu",
  "spamfree24.com",
  "spamfree24.de",
  "spamfree24.eu",
  "spamfree24.info",
  "spamfree24.net",
  "spamfree24.org",
  "spamgoes.in",
  "spamgourmet.com",
  "spamgourmet.net",
  "spamgourmet.org",
  "spamherelots.com",
  "spamhereplease.com",
  "spamhole.com",
  "spamify.com",
  "spaminator.de",
  "spamkill.info",
  "spaml.com",
  "spaml.de",
  "spamlot.net",
  "spammotel.com",
  "spamobox.com",
  "spamoff.de",
  "spamsalad.in",
  "spamslicer.com",
  "spamspot.com",
  "spamstack.net",
  "spamthis.co.uk",
  "spamthisplease.com",
  "spamtrail.com",
  "spamtroll.net",
  "speed.1s.fr",
  "spoofmail.de",
  "spybox.de",
  "squizzy.de",
  "sry.li",
  "ssl.tls.cloudns.asia",
  "stanfordujjain.com",
  "starlight-breaker.net",
  "stinkefinger.net",
  "stop-my-spam.cf",
  "stuffmail.de",
  "supergreatmail.com",
  "supermailer.jp",
  "superrito.com",
  "superstachel.de",
  "suremail.info",
  "susi.ml",
  "sutiami.tk",
  "t.psh.me",
  "tafmail.com",
  "tagyourself.com",
  "tapchicuoihoi.com",
  "teewars.org",
  "teleworm.com",
  "teleworm.us",
  "temp-mail.com",
  "temp-mail.org",
  "temp-mail.ru",
  "temp.emeraldwebmail.com",
  "temp.headstrong.de",
  "temp.mail.y59.jp",
  "tempail.com",
  "tempalias.com",
  "tempe-mail.com",
  "tempemail.biz",
  "tempemail.co.za",
  "tempemail.com",
  "tempemail.net",
  "tempinbox.co.uk",
  "tempinbox.com",
  "tempmail.co",
  "tempmail.de",
  "tempmail.eu",
  "tempmail.it",
  "tempmail.pp.ua",
  "tempmail.pro",
  "tempmail.pw",
  "tempmail.space",
  "tempmail.us",
  "tempmail2.com",
  "tempmailapp.com",
  "tempmaildemo.com",
  "tempmailer.com",
  "tempmailer.de",
  "tempomail.fr",
  "temporamail.com",
  "temporarily.de",
  "temporarioemail.com.br",
  "temporaryemail.net",
  "temporaryemail.us",
  "temporaryforwarding.com",
  "temporaryinbox.com",
  "temporarymailaddress.com",
  "tempthe.net",
  "tempymail.com",
  "test.com",
  "thankyou2010.com",
  "thc.st",
  "thecloudindex.com",
  "thelimestones.com",
  "thisisnotmyrealemail.com",
  "thismail.net",
  "thrma.com",
  "throwam.com",
  "throwawayemailaddress.com",
  "throwawaymail.com",
  "tilien.com",
  "tinoza.org",
  "tittbit.in",
  "tizi.com",
  "tmail.ws",
  "tmailinator.com",
  "toomail.biz",
  "top101.de",
  "top1mail.ru",
  "tradermail.info",
  "trash-amil.com",
  "trash-mail.at",
  "trash-mail.com",
  "trash-mail.de",
  "trash-me.com",
  "trash2009.com",
  "trash2010.com",
  "trash2011.com",
  "trashbox.eu",
  "trashdevil.com",
  "trashdevil.de",
  "trashemail.de",
  "trashimail.de",
  "trashmail.at",
  "trashmail.com",
  "trashmail.de",
  "trashmail.me",
  "trashmail.net",
  "trashmail.org",
  "trashmail.ws",
  "trashmailer.com",
  "trashymail.com",
  "trialmail.de",
  "trillianpro.com",
  "trollproject.com",
  "twinmail.de",
  "twoweirdtricks.com",
  "tyldd.com",
  "uggsrock.com",
  "umail.net",
  "unmail.ru",
  "upliftnow.com",
  "uplipht.com",
  "uroid.com",
  "us.af",
  "username.e4ward.com",
  "venompen.com",
  "veryrealemail.com",
  "vidchart.com",
  "viditag.com",
  "viewcastmedia.com",
  "viewcastmedia.net",
  "viewcastmedia.org",
  "viralplays.com",
  "vkcode.ru",
  "vmail.me",
  "vmani.com",
  "vomoto.com",
  "vp.ycare.de",
  "vpn.st",
  "vsimcard.com",
  "vubby.com",
  "wasteland.rfc822.org",
  "webemail.me",
  "webm4il.info",
  "wegwerfadresse.de",
  "wegwerfemail.de",
  "wegwerfmail.de",
  "wegwerfmail.info",
  "wegwerfmail.net",
  "wegwerfmail.org",
  "wetrainbayarea.com",
  "wetrainbayarea.org",
  "wh4f.org",
  "whyspam.me",
  "wickmail.net",
  "wilemail.com",
  "willhackforfood.biz",
  "willselfdestruct.com",
  "winemaven.in",
  "wronghead.com",
  "wuzup.net",
  "wuzupmail.net",
  "www.e4ward.com",
  "www.mailinator.com",
  "wwwnew.eu",
  "x.ip6.li",
  "xagloo.com",
  "xemaps.com",
  "xents.com",
  "xmail.com",
  "xmaily.com",
  "xmailsgenerator.com",
  "xn--9kq967o.com",
  "xoxy.net",
  "y.epb.ro",
  "y7mail.com",
  "ya.yomail.info",
  "yep.it",
  "yogamaven.com",
  "yopmail.com",
  "yopmail.fr",
  "yopmail.net",
  "yourdomain.com",
  "ypmail.webarnak.fr.eu.org",
  "yuurok.com",
  "z1p.biz",
  "za.com",
  "zehnminuten.de",
  "zehnminutenmail.de",
  "zetmail.com",
  "zippymail.info",
  "zoaxe.com",
  "zoemail.org",
  "zomg.info",
  "zxcv.com",
  "zxcvbnm.com",
  "zzz.com"
]
