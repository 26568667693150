import React, { useContext, useState } from "react";
import { PostContext } from "../../../contexts/hr-portal-context/Context";
import { toast } from "react-toastify";
import { FaEdit } from "react-icons/fa";

interface Props {
  formIndex: () => void;
  setFormIndex: any;
}

function PreviewPostDetails({ formIndex, setFormIndex }: Props) {
  const context = useContext(PostContext);
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  if (!context) {
    throw new Error("AddJobDetails must be used within a PostContext.Provider");
  }

  const { postDetailsForm, setPostDetailsForm } = context;
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    try {
      formIndex();
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  console.log(postDetailsForm);

  return (
    <main className="bg-[#fff] flex items-center justify-center w-full my-4 rounded-xl">
      <div className="flex flex-col w-[95%] md:w-[70%] p-6 md:p-8 lg:p-16 gap-y-8 md:gap-y-10 lg:gap-y-12">
        <section className="job-details space-y-6">
          <div className="flex justify-between">
            <h1 className="text-[#24336B] text-[18px] font-semibold">
              Job Details
            </h1>
            <FaEdit
              className="cursor-pointer text-[#24336B]"
              onClick={() => setFormIndex(1)}
              size={28}
            />
          </div>

          <div className="grid grid-cols-2 gap-y-4">
            <label className="preview-label">Job Title</label>
            <div className="preview-field">{postDetailsForm.jobType}</div>

            <label className="preview-label">Job description</label>
            <div className="preview-field">
              {showMore ? (
                <>{postDetailsForm.jobDescription}</>
              ) : (
                <>{postDetailsForm.jobDescription.substring(0, 100)}...</>
              )}
              <span
                className="text-blue-500 cursor-pointer ml-2"
                onClick={toggleShowMore}
              >
                {showMore ? "Show less" : "Show more"}
              </span>
            </div>

            <label className="preview-label">Company Name</label>
            <div className="preview-field">{postDetailsForm.companyName}</div>

            <label className="preview-label">Office address</label>
            <div className="preview-field">{postDetailsForm.officeAddress}</div>

            <label className="preview-label">Job type</label>
            {/* <div className="preview-field">{postDetailsForm.jobType} {"("}{postDetailsForm.internshipDuration?.startDate} - {postDetailsForm.internshipDuration?.endDate}{")"}</div> */}

            <div className="preview-field">
              <span>{postDetailsForm.jobType} </span>
              <span>
                {postDetailsForm.internshipDuration && postDetailsForm.jobType === "Internship" &&
                  `(${postDetailsForm.internshipDuration?.startDate} - ${postDetailsForm.internshipDuration?.endDate})`}
              </span>
            </div>

            <label className="preview-label">Job model</label>
            <div className="preview-field">{postDetailsForm.jobModel}</div>

            <label className="preview-label">Job Location</label>
            <div className="preview-field">{postDetailsForm.jobLocation}</div>

            <label className="preview-label">Salary range (in LPA)</label>
            <div className="preview-field">
              {postDetailsForm.salaryRange.min} -{" "}
              {postDetailsForm.salaryRange.max}
            </div>

            <label className="preview-label">Perks</label>
            <div className="preview-field">
              {postDetailsForm.perks ? postDetailsForm.perks.join(", ") : "-"}
            </div>
          </div>
        </section>

        <section className="applicant-qualification space-y-6">
          <div className="flex justify-between">
            <h1 className="text-[#24336B] text-[18px] font-semibold">
              Applicant Qualification
            </h1>
            <FaEdit
              className="cursor-pointer text-[#24336B]"
              onClick={() => setFormIndex(2)}
              size={28}
            />
          </div>

          <div className="grid grid-cols-2 gap-y-4">
            <label className="preview-label">Minimum education</label>
            <div className="preview-field">
              {postDetailsForm.minimumEducation}
            </div>

            <label className="preview-label">Experience (in years)</label>
            <div className="preview-field">{postDetailsForm.experience}</div>

            <label className="preview-label">Required skills</label>
            <div className="preview-field">{postDetailsForm.skills}</div>

            <label className="preview-label">Languages</label>
            <div className="preview-field">
              {postDetailsForm.languages
                ? postDetailsForm.languages.join(", ")
                : "-"}
            </div>

            <label className="preview-label">Other requirements</label>
            <div className="preview-field">
              {postDetailsForm.otherRequirements}{" "}
            </div>
          </div>
        </section>
        <div className="text-center">
          <button
            onClick={handleSubmit}
            className="w-full bg-gradient-to-r from-[#139AFD] to-[#436AF4] font-semibold text-[16px] text-white text-center py-4 md:py-6 rounded-md disabled:opacity-50"
            disabled={loading}
          >
            {loading ? "Saving..." : "Proceed"}
          </button>
        </div>
      </div>
    </main>
  );
}

export default PreviewPostDetails;
