import { useRef } from "react";
import SplitText from "../components/SplitText";
import Navbar from "../components/Navbar";
import left from "../assets/imgs/left.webp";
import right from "../assets/imgs/right.webp";
import Banner from "../components/Banner";
import Card from "../components/Card";
import logo1 from "../assets/imgs/network.png";
import logo2 from "../assets/imgs/career.png";
import logo3 from "../assets/imgs/learning.png";
import logo4 from "../assets/imgs/community.png";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
import Footer from "../components/Footer";
import {Helmet} from 'react-helmet'

const Home = () => {
  const videoSource = require("../assets/videos/main.webm");
  const missionRef = useRef<HTMLDivElement>(null)
  const visionRef = useRef<HTMLDivElement>(null)
  const cardRef = useRef<HTMLDivElement>(null)
  const triggerRef1 = useRef<HTMLDivElement>(null)
  const triggerRef2 = useRef<HTMLDivElement>(null)

  gsap.registerPlugin(ScrollTrigger)

  useGSAP(()=>{
    gsap.from(missionRef.current,{
      opacity:0,
      y:-320,
      duration:1.4,
      scrollTrigger:{
        trigger: triggerRef1.current,
        scroller: "body",
        start: "top 40%",
      }
    })
    gsap.from(visionRef.current,{
      opacity:0,
      y:320,
      duration:1.4,
      scrollTrigger:{
        trigger: triggerRef1.current,
        scroller: "body",
        start: "top 40%",
      }
    })
    // gsap.from(cardRef.current,{
    //   opacity:0,
    //   y:-120,
    //   duration:1.8,
    //   scrollTrigger:{
    //     trigger: triggerRef2.current,
    //     scroller: "body",
    //     start: "top 80%",
    //   }
    // })
  })


  return (
    <>
      <Helmet>
        <title>Xampus - AI Enabled Professional Network</title>
        <meta name="description" content="Unlock your full professional potential with our tips! Discover how AI can transform your job search experience and lead you to outstanding career opportunities. Don’t miss your chance to grow!" />
        <meta name="keywords" content="xampus, home page, jobs, career" />
      </Helmet>
      <Navbar styles="bg-[#eef6fd]" />
      <div className="relative text-center overflow-hidden bg-[#eef6fd] h-[31rem] md:h-[50rem] lg:h-[60rem]">
        <div className="text-center inline-block mt-[2rem] md:mt-[2rem]">
          <h1 className="lg:text-[5.6rem] text-[2rem] font-bold md:text-[4rem]">
            <SplitText text="Xampus" />
          </h1>
        </div>

        <h1 className="heading text-[#030A74] lg:text-[1.9rem] text-[1rem] font-bold md:text-[1.6rem]">
          <span className="text-yellow-600">Jobs, </span>Network and AI
        </h1>

        <div className="left-img-div">
          <img src={left} alt="" loading="lazy" className="left left-img" />
        </div>

        <div className="video-div z-40">
          <video autoPlay muted playsInline className="video">
            <source src={videoSource} type="video/webm" />
          </video>
        </div>

        <div className="right-img-div">
          <img src={right} alt="" loading="lazy" className="right right-img" />
        </div>
      </div>

      <Banner />

      <div
        className="min-h-screen bg-[#C3D0FF] text-[#071143] text-center flex flex-col justify-center items-center gap-y-[6rem] py-12 px-6 overflow-hidden" ref={triggerRef1}
      >
        <div
          className="flex flex-col items-center justify-center" ref={missionRef}>
          <h1 className={`text-[2rem] md:text-[2.5rem] font-bold `}>
            Our Mission
          </h1>
          <div className="flex flex-col md:flex-row w-full md:w-[70%] gap-x-[3rem] items-center">
            <p className="text-start text-[1rem] md:text-[1.4rem] ">
              At Xampus, our mission is to transform professional networking in
              India with AI technology. We aim to connect, empower, and elevate
              professionals, helping them achieve their career goals and drive
              innovation across industries. Join us in creating limitless
              professional opportunities. 
            </p>
          </div>
        </div>

        <div
          className="flex flex-col items-center justify-center" ref={visionRef}>
          <h1 className="text-[2rem] md:text-[2.5rem] font-bold">Our Vision</h1>
          <div className="flex flex-col md:flex-row w-full md:w-[70%] gap-x-[3rem] items-center">
            <p className="text-start text-[1rem] md:text-[1.4rem] ">
              Our vision at Xampus is to be the leading platform that redefines
              professional networking in India. We aspire to create a vibrant
              ecosystem where talent and opportunities converge seamlessly,
              fostering growth and innovation. By harnessing the power of AI, we
              aim to empower every professional to reach their full potential
              and shape a brighter future for themselves and their industries.
            </p>
          </div>
        </div>
      </div>

      <div className="min-h-screen bg-[#f0f8ff] p-6 overflow-hidden " ref={triggerRef2}>
        <div className="space-y-8 text-[#0A1132] text-center" ref={cardRef}>
          <h3 className="text-[2rem] md:text-[2.5rem]">What we Offer</h3>

          <div className="flex flex-wrap justify-around items-center">
            <div className="p-4">
              <Card
                logo={logo1}
                title="Networking"
                paragraph="Connect with like-minded professionals, industry leaders, and potential employers. Xampus offers a robust networking experience tailored to your professional needs."
              />
            </div>
            <div className="p-4">
              <Card
                logo={logo2}
                title="Career Opportunities"
                paragraph="Discover job opportunities that match your skills and career goals. Our advanced matching algorithms ensure you find the best fit for your next career move."
              />
            </div>
            <div className="p-4">
              <Card
                logo={logo3}
                title="Learning & Development"
                paragraph="Access a wealth of resources, including courses, webinars, and articles, designed to help you upskill and stay competitive in your field."
              />
            </div>
            <div className="p-4">
              <Card
                logo={logo4}
                title="Community"
                paragraph="Join groups and forums to share knowledge, discuss industry trends, and seek advice from peers and experts."
              />
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default Home;
