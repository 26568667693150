import React, { useEffect, useState } from "react";
import emptyJob from "../../../assets/imgs/emptyJobs.svg";
import logo from "../../../assets/imgs/xlogoBlue.png";
import { useNavigate } from "react-router-dom";

type JobData = {
  jobRole: string;
  jobModel: string;
  companyName: string;
  formattedTime: string;
  applicants: number;
  jobLocation: string;
};

function JobsSection({postedJobs}: any) {
  const navigate = useNavigate()
  const [allJobs, setAllJobs] = useState<JobData[]>(postedJobs);

  useEffect(()=> {
    setAllJobs(postedJobs)
  }, [postedJobs])

  if (allJobs.length <= 0) {
    return (
      <main className="flex items-center justify-center">
        <section className="border-[1.4px] border-gray-400 rounded-[20px] w-[95%] md:w-[90%] lg:w-[80%] grid items-center justify-center">
          <div className="flex flex-col items-center justify-center w-[288px] md:w-[428px] px-2 py-8 lg:py-12">
            <img src={emptyJob} alt="" className="w-[60%]" />
            <h3 className="text-[18px] md:text-[24px] text-[#24336B] font-semibold">
              No Jobs Posted Yet !
            </h3>
            <p className="text-[12px] md:text-[14px] text-center text-[#000000b4] leading-4 md:leading-6">
              No problem! Start posting job openings <br /> easily and reach top
              talent now!
            </p>
            <button onClick={()=> navigate('/hr-portal/add-post-details')} className="w-full bg-gradient-to-r from-[#139AFD] to-[#436AF4] mt-8 lg:mt-10 font-semibold text-[14px] text-white text-center py-3 md:py-4 rounded-md disabled:opacity-50">
              Post a New Job
            </button>
          </div>
        </section>
      </main>
    );
  }
  return (
    <section className="border-[1.4px] border-gray-400 rounded-[20px] w-[95%] md:w-[90%] lg:w-[80%]">
      <div className="flex flex-col px-5 md:px-16 py-8">
        <div>
          <p className="text-[#24336B] text-[22px] font-medium">
            Posted Jobs ({postedJobs.length})
          </p>
        </div>

        <div className="flex gap-3 my-4">
          <button
            className={`font-medium w-[90px] h-[46px] rounded-full bg-blue-500 text-[#fff]`}
          >
            All
          </button>
          <button
            className={`font-medium w-[90px] h-[46px] rounded-full bg-gray-200 text-[#0008]`}
          >
            Active
          </button>
          <button
            className={`font-medium w-[90px] h-[46px] rounded-full bg-gray-200 text-[#0008]`}
          >
            Closed
          </button>
        </div>

        <div className="mt-6 space-y-6 w-full">
          {allJobs.map((item, index) => (
            <div className="flex gap-4 w-full" key={index}>
              <img
                src={logo}
                alt=""
                className="w-[50px] h-[50px] rounded-md mt-1"
              />
              <div className="flex w-full items-center justify-between">
                <div className="">
                  <h4 className="text-[16px] md:text-[20px] text-[#000000bc] font-medium">
                    {item.jobRole}
                  </h4>
                  <p className="text-[12px] md:text-[16px] text-[#000000bc] leading-4">
                    {item.companyName}
                  </p>
                  <p className="text-[12px] md:text-[16px] text-[#000000bc] leading-4">
                    {item.jobLocation} ({item.jobModel})
                  </p>
                  <p className="text-[12px] md:text-[16px] text-[#000000bc] leading-4 pt-2 md:pt-3">
                    {item.formattedTime}
                  </p>
                  <p className="text-[12px] md:text-[16px] text-[#000000bc] leading-4">
                    100+ Applicants
                  </p>
                </div>
                <span className="text-[32px] text-[#24336B] font-medium">{">"}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default JobsSection;
