import React from 'react';
import notFound from '../assets/imgs/404.svg';

const NotFound = () => {
  return (
    <div className="flex items-center justify-center min-h-screen">
      <img src={notFound} alt="Page Not Found" className="max-h-screen h-auto mx-auto" loading="lazy" />
    </div>
  );
}

export default NotFound;
