import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import {
  User,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  createUserWithEmailAndPassword,
  signOut as firebaseSignOut,
  onAuthStateChanged,
  updateProfile,
  sendEmailVerification,
} from "firebase/auth";
import { formatDistanceToNow } from 'date-fns';
import { collection, query, where, getDocs, addDoc, doc, setDoc, serverTimestamp } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid"; 
import { auth, db, storage } from "../../firebase/firebase";

interface AuthContextType {
  currentUser: User | null;
  authLoading: boolean;
  memberLogin: (email: string, password: string) => Promise<void>;
  userLogout: () => Promise<void>;
  accountDeletion: (info:string) => Promise<void>;
  userLogin: (email: string, password: string) => Promise<void>;
  userRegister: ( email: string, password: string, fullname: string ) => Promise<void>;
  hrLogin: (email: string, password: string) => Promise<void>;
  hrRegister: ( email: string, password: string ) => Promise<void>;
  hrForgotPassword: ( email: string ) => Promise<void>;
  addHrDetails: ( details: object ) => Promise<void>;
  addJobDetails: ( details: object ) => Promise<void>;
  fetchJobsData: (userId: string) => Promise<{ id: string }[]>;
  addPost: (image: File, title: string, postText: string, aiQuestionsArr: any[]) => Promise<void>; // Updated the parameters
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [authLoading, setAuthLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setAuthLoading(false); // Set loading to false once we know the auth state
    });

    return () => unsubscribe();
  }, []);

  const addPost = async (image: File, title: string, postText: string, aiQuestionsArr: any[]) => {
    try {
      const newKey = `${Date.now()}-${uuidv4()}`;
      console.log('newKey', newKey);

      const imageRef = ref(storage, `posts/${image.name}--${Date.now()}`);
      const upload = await uploadBytes(imageRef, image);
      const imageUrl = await getDownloadURL(upload.ref);

      // Dummy user profile data, replace it with actual user profile data
      const useProfileData = {
        designation: "Developer",
        fullname: currentUser?.displayName || "Anonymous",
        profileDownloadURL: imageUrl
      };

      await setDoc(doc(db, "globalPostRef", newKey), {
        postImage: imageUrl,
        designation: useProfileData.designation,
        fullname: useProfileData.fullname,
        profileDownloadURL: useProfileData.profileDownloadURL,
        postedTime: serverTimestamp(),
        likes: 0,
        comments: 0,
        caption: postText,
        aiQuestionsArr: aiQuestionsArr,
        visible: true,
        id: newKey,
        title: title,
        userId: currentUser?.uid,
      });

      toast.success("Post added successfully!");

    } catch (error: any) {
      toast.error(error.message);
    }
  };

  const userLogin = async (email: string, password: string) => {
    try {
      await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      toast.success("User login successful!");
    } catch (error: any) {
      throw error;
    }
  };

  const userRegister = async (
    email: string,
    password: string,
    fullname: string
  ) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      // Update the user's profile with the display name
      await updateProfile(user, {
        displayName: fullname,
      });
      toast.success("User registration successful!");
    } catch (error: any) {
      throw error;
    }
  };

  const hrLogin = async (email: string, password: string) => {
    try {
      await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      toast.success("HR login successful!");
    } catch (error: any) {
      throw error;
    }
  };

  const hrRegister = async (
    email: string,
    password: string,
  ) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      await sendEmailVerification(user)
      // if(!user.emailVerified){
      //   throw new Error("Email not verified. Please check your inbox and verify your email.");
      // }
      toast.success("HR registration successful!");
    } catch (error: any) {
      throw error;
    }
  };

  const hrForgotPassword = async (
    email: string
  ) => {
    try {
      await sendPasswordResetEmail(
        auth,
        email,
      );
      toast.success("Email Sent")
    } catch (error: any) {
      throw error;
    }
  };

  const addHrDetails = async (details: object) => {
    try {
      const usersCollectionRef = collection(db, "Users");
  
      // Add the postedBy field to the details object
      const detailsWithPostedBy = {
        ...details,
        userId: currentUser?.uid,
      };
  
      const docRef = await addDoc(usersCollectionRef, detailsWithPostedBy);
      
    } catch (error: any) {
      throw error;
    }
  };

  const addJobDetails = async (details: object) => {
    try {
      const usersCollectionRef = collection(db, "XampusJobs");
  
      // Add the postedBy field to the details object
      const detailsWithPostedBy = {
        ...details,
        postedBy: currentUser?.uid,
        postedTime: serverTimestamp(),
      };
  
      const docRef = await addDoc(usersCollectionRef, detailsWithPostedBy);
      
    } catch (error: any) {
      throw error;
    }
  };

  const fetchJobsData = async (userId: string) => {
    try {
      const q = query(collection(db, "XampusJobs"), where("postedBy", "==", userId));
      const querySnapshot = await getDocs(q);
  
      // Map through documents and return them with formatted timestamps
      const jobs = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        const postedTime = data.postedTime; // Firebase Timestamp
  
        // Check if postedTime exists and format it using date-fns
        const formattedTime = postedTime ? formatDistanceToNow(postedTime.toDate(), { addSuffix: true }) : 'Unknown time';
  
        return {
          id: doc.id,
          ...data,
          formattedTime, // Add formatted time to the job data
        };
      });
  
      return jobs; // Return the job data with formatted timestamps
  
    } catch (error: any) {
      throw error;
    }
  };
  

  const memberLogin = async (email: string, password: string) => {
    try {
      const q = query(
        collection(db, "memberSignIn"),
        where("email", "==", email),
        where("password", "==", password)
      );

      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        throw new Error("User not found");
      } else {
        setCurrentUser(auth.currentUser);
        toast.success("Sign-in successful!");
      }
    } catch (error: any) {
      throw error;
    }
  };

  const userLogout = async () => {
    try {
      await firebaseSignOut(auth);
      toast.success("Sign-out successful!");
    } catch (error) {
      toast.error("Error signing out");
    }
  };

  const accountDeletion = async (info:string) => {
    try {
      if(!currentUser){
        toast.error("Please login in order to send deletion request");
        return
      }
      await addDoc(collection(db, "AccountDeleteRequest"),{
        email_or_phone: info,
        userId: currentUser?.uid
      });

      toast.success("Request sent!");
    } catch (error:any) {
      toast.error( error.message);
    }
  };

  const value = {
    currentUser,
    authLoading,
    memberLogin,
    userLogout,
    accountDeletion,
    addPost,
    userLogin,
    userRegister,
    addHrDetails,
    hrLogin,
    hrRegister,
    hrForgotPassword,
    addJobDetails,
    fetchJobsData
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
