import React from 'react'

interface Props{
    profileImg: string;
    name: string;
    designation: string;
}

const ProfileCard = ({profileImg, name, designation}: Props) => {
    return (
        <div className="z-20 w-[10rem] h-[12rem] md:w-[12rem] md:h-[14rem] lg:w-[19rem] lg:h-[21rem] py-[0.8rem] md:py-[1.5rem] mx-auto bg-white shadow-card rounded-[1rem] overflow-hidden">
          <div className="flex flex-col items-center p-3 lg:p-6">
            <img 
              src={profileImg} 
              alt={name} 
              loading='lazy'
              className="w-[5.5rem] h-[5.5rem] lg:w-[9.5rem] lg:h-[9.5rem] rounded-full object-cover mb-1 md:mb-2 lg:mb-3"
            />
            <div className='mt-1 md:mt-2 lg:mt-3'>
              <h2 className="text-[0.9rem] md:text-[1rem] lg:text-[1.25rem] font-semibold text-center">{name}</h2>
              <p className="text-[#24336B] text-[0.8rem] lg:text-[1rem] text-center mt-1 leading-4">{designation}</p>
            </div>
          </div>
        </div>
      );
}

export default ProfileCard
