import Navbar from "../components/Navbar";
import aboutImg from "../assets/imgs/about.svg";
// Images
import storyImg from "../assets/imgs/storyImg.svg";
import halfMoon from "../assets/imgs/halfmoon.svg";
import daimeter from "../assets/imgs/daimeter.svg";
import dots from "../assets/imgs/dots.svg";
import polygon from "../assets/imgs/Polygon 2.svg";
import ellipse1 from "../assets/imgs/Ellipse 3.svg";
import ellipse2 from "../assets/imgs/Ellipse 4.svg";
// Icons
import { GrApple } from "react-icons/gr";
import { FaGooglePlay } from "react-icons/fa";
import { toast } from "react-toastify";
import Footer from "../components/Footer";

const About = () => {

  const handleIosClick = () => {
    toast.warn(
      <div>
        <strong>Coming soon!</strong>
        <div>
          The iOS version of our app will be available shortly. Stay tuned!
        </div>
      </div>
    );
  };
  return (
    <>
      <Navbar styles="bg-[#eef6fd]" />
      <div
        className="relative min-h-[50rem] md:min-h-screen flex flex-col justify-center items-center bg-cover bg-center bg-no-repeat px-4 md:px-0"
        style={{ backgroundImage: `url(${aboutImg})` }}
      >
        <div className="relative z-10 text-center text-white mt-10 md:mt-0 mb-6 md:mb-12">
          <h1 className="text-5xl lg:text-6xl font-bold">About Us</h1>
        </div>
        <div className="relative z-10 w-full md:w-[80%] space-y-6 md:space-y-10">
          <p className="text-white text-left md:text-center text-base md:text-lg lg:text-xl tracking-wider">
            Xampus is the new age professional network designed specifically for
            India’s dynamic and diverse workforce. We aim to revolutionize the
            way professionals connect, collaborate, and grow their careers in
            the digital age.
          </p>

          <p className="text-white text-left md:text-center text-base md:text-lg lg:text-xl tracking-wider">
            India’s premier AI-enabled professional networking platform. Here,
            you can connect, collaborate, and cultivate your career with
            like-minded professionals from diverse industries. Whether you’re
            looking to unlock new opportunities, showcase your expertise, or
            elevate your professional journey, Xampus is the place to be. Join
            us today and take the next step towards realizing your career
            aspirations. Your future of professional growth and success starts
            here.
          </p>
        </div>
      </div>

      <div className="min-h-[40rem] relative grid grid-cols-1 md:grid-cols-2 justify-center items-center gap-y-8 gap-x-8 px-8 md:px-4 py-[3rem] md:py-[6rem] bg-[#0f1a42]">
        <img
          src={polygon}
          alt=""
          className="absolute left-[10rem] bottom-[1rem] md:left-[12rem] lg:bottom-[2rem] lg:left-[15rem] h-[3.3rem] md:h-[5rem]"
        />

        <img
          src={ellipse1}
          alt=""
          className="absolute top-0 left-0 h-[10rem] md:h-[18rem]"
        />

        <img
          src={ellipse2}
          alt=""
          className="absolute top-[3rem] right-[2rem] md:top-[8rem] md:right-[6rem] lg:top-[10rem] lg:right-[8rem] h-[4rem] md:h-[8rem]"
        />

        <div className="flex justify-center items-center">
          <img
            src={storyImg}
            alt=""
            className="h-[12rem] md:h-[14rem] lg:h-[15rem]"
          />
        </div>

        <div className="w-[95%] lg:w-[75%] text-start text-white">
          <h2 className="text-[2rem] md:text-[2.5rem] font-bold mb-6">
            Our Story
          </h2>
          <p className="text-base md:text-lg lg:text-xl tracking-wider">
            Founded in 2024, Xampus was born out of a desire to create a more
            inclusive and tailored professional network for the Indian market.
            Recognizing the unique challenges and opportunities within India’s
            job market, our founders set out to build a platform that bridges
            the gap between talent and opportunity.
          </p>
        </div>
      </div>

      <div className="relative min-h-[50rem] flex flex-col items-center pb-[6rem] bg-[#acc1da] space-y-[6rem]">
        <img
          src={halfMoon}
          alt=""
          className="absolute h-[30rem] md:h-[40rem] lg:h-[55rem] bottom-2 lg:bottom-5"
        />
        <div className="w-11/12 md:w-4/5 lg:w-2/3 text-start md:text-center">
          <h2 className="text-2xl md:text-3xl font-bold mb-6">
            We started with a dream
          </h2>
          <p className="text-base md:text-lg lg:text-xl">
            Xampus's dream is to revolutionize professional networking for
            India's diverse and dynamic workforce by creating a platform that
            facilitates meaningful connections, fosters collaboration, and
            supports career growth in the digital era. They aim to empower
            professionals to advance their careers and achieve their
            professional goals through a tailored and innovative networking
            experience.
          </p>
        </div>

        <div className="w-11/12 md:w-4/5 lg:w-2/3 text-start md:text-center">
          <h2 className="text-2xl md:text-3xl font-bold mb-6">Growth</h2>
          <p className="text-base md:text-lg lg:text-xl">
            Since our inception, we have grown into a vibrant community of over
            10 professionals from various industries and backgrounds. Our team
            of 10+ dedicated individuals works tirelessly to improve and expand
            our services.
          </p>
        </div>
      </div>
      <div className="min-h-[15rem] relative md:min-h-[25rem] flex flex-col justify-center items-center bg-[#E6F2FC] gap-y-10 md:gap-y-16 px-4 md:px-8">
        <img
          src={daimeter}
          alt=""
          className="absolute top-0 left-0 h-[10rem] md:h-[15rem] lg:h-[20rem] z-0"
        />
        <img
          src={dots}
          alt=""
          className="absolute bottom-3 right-0 h-[5rem] md:h-[8rem] lg:h-[10rem] z-0"
        />
        <h2 className="text-[2rem] md:text-[3rem] font-semibold text-center">
          Get it on
        </h2>
        <div className="flex gap-2 md:gap-6 flex-wrap justify-center">
          <div className="flex items-center gap-2 md:gap-4 px-3 py-2 md:px-6 md:py-4 text-[0.8rem] md:text-[1rem] font-semibold cursor-pointer text-black bg-[#fff] border border-black rounded-xl md:rounded-2xl" onClick={handleIosClick}>
            <GrApple className="text-[1.5rem] md:text-[2rem]" />
            <p className="text-[1rem] md:text-[1.2rem] font-normal">
              App Store
            </p>
          </div>
          <a
            href="https://play.google.com/store/apps/details?id=com.xampus.aiapp&hl=en-IN"
            target="blank"
          >
            <div className="flex items-center gap-2 md:gap-4 px-3 py-2 md:px-6 md:py-4 text-[0.8rem] md:text-[1rem] text-[#fff] font-semibold cursor-pointer bg-[#060F40] rounded-xl md:rounded-2xl">
              <FaGooglePlay className="text-[1.5rem] md:text-[2rem]" />
              <p className="text-[1rem] md:text-[1.2rem] font-normal">
                Google Play
              </p>
            </div>
          </a>
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default About;
