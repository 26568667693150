import React, { useEffect, useState } from "react";
import ProgressIndicator from "../../components/hr-portal/Add-Post_Progress-Indicator/Progress-Indicator";
import AddJobDetails from "../../components/hr-portal/AddPostForms/AddJobDetails";
import AddApplicantQualification from "../../components/hr-portal/AddPostForms/AddApplicantQualification";
import PreviewPostDetails from "../../components/hr-portal/AddPostForms/PreviewPostDetails";
import {
  PostContext,
  PostDetailsForm,
} from "../../contexts/hr-portal-context/Context"; // Adjust import path as necessary
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/imgs/xlogoBlue.png";
import ChooseYourPlan from "../../components/hr-portal/AddPostForms/ChooseYourPlan";
import { useAuth } from "../../contexts/firebaseContext/Context";

function AddPostDetails() {

  const navigate = useNavigate();
  const { currentUser, authLoading } = useAuth();

  useEffect(() => {
    if (!authLoading && !currentUser) {
      navigate("/auth/hr-portal");
    }
  }, [currentUser, authLoading, navigate]);
  
  const steps = ["Step 1", "Step 2", "Step 3", "Step 4"];
  const [formIndex, setFormIndex] = useState(1);
  const [postDetailsForm, setPostDetailsForm] = useState<PostDetailsForm>({
    jobRole: "",
    jobDescription: "",
    companyName: "",
    officeAddress: "",
    jobType: "",
    jobModel: "",
    jobLocation: "",
    salaryRange: {
      min: "",
      max: "",
    },
    perks: [],
    minimumEducation: "",
    experience: "",
    skills: "",
    languages: [],
    otherRequirements: "",
    internshipDuration: {
      startDate: "",
      endDate: "",
    }, // Initialize internshipDuration
  });
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [formIndex]);

  return (
    <main className="w-full min-h-screen bg-[#fff]">
      <PostContext.Provider value={{ postDetailsForm, setPostDetailsForm }}>
        <div className="min-h-screen grid grid-cols-1 md:grid-cols-[1fr_2fr] overflow-hidden">
          <div className="bg-[#24336B] hidden md:flex flex-col items-center pt-[2rem]">
            <div className="w-[85%]">
              <Link to="/">
                <img src={logo} alt="Logo" className="w-12 rounded-lg" />
              </Link>
              <h1 className="text-start text-[#fff] text-[32px] font-semibold my-[5.5rem] leading-tight">
                {formIndex === 1 &&
                  "Find top talent in 4 simple steps. Post your job and reach millions instantly!"}
                {formIndex === 2 &&
                  "Describe your dream candidate and we'll help you find them"}
                {formIndex === 3 &&
                  "Review Your Job Details : Ensure Everything is Accurate"}
                {formIndex === 4 &&
                  "Choose Your Free Pack , Hassle Free. It’s on us"}
              </h1>
              <ProgressIndicator currentStep={formIndex} steps={steps} />
            </div>
          </div>

          <div className="py-[3rem]">
            <div className="px-2 md:px-4 lg:px-8">
              <h1 className="text-center text-[#0A1E67] text-[20.4px] md:text-[24px] font-bold">
                {formIndex <= 3 ? "Post a new Job Opening for Xampus" : "Choose a Pack on basis of your hiring needs"}
                {/* {formIndex === 4 && ""} */}
              </h1>
              <p className="text-center text-[12px] md:text-[16px] font-medium">
                {formIndex === 1 &&
                  "We’ll use this information to find the best candidate for this Job Opening"}{" "}
                {formIndex === 2 &&
                  "We’ll use this qualification details to make your job visible to right applicant."}{" "}
                {formIndex === 3 &&
                  "Please take a look to make sure everything look’s good !"}{" "}
                {formIndex === 4 &&
                  "Welcome to Xampus : 100 % Off on Your First Job posting"}
              </p>
            </div>
            {formIndex === 1 && (
              <AddJobDetails formIndex={() => setFormIndex(formIndex + 1)} />
            )}
            {formIndex === 2 && (
              <AddApplicantQualification
                formIndex={() => setFormIndex(formIndex + 1)}
              />
            )}
            {formIndex === 3 && (
              <PreviewPostDetails
                formIndex={() => setFormIndex(formIndex + 1)}
                setFormIndex={setFormIndex}
              />
            )}
            {formIndex === 4 && (
              <ChooseYourPlan
              formIndex={() => setFormIndex(formIndex + 1)}
              />
            )}
          </div>
        </div>
      </PostContext.Provider>
    </main>
  );
}

export default AddPostDetails;
