import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";

const Privacy_Policy = () => {
  return (
    <>
      <Helmet>
        <title>Privacy Policy | Xampus</title>
        <meta name="description" content="Learn how Xampus collects, uses, and protects your personal information. Read our detailed privacy policy to understand your rights and our practices." />
        <meta name="keywords" content="Privacy policy, Xampus, Company" />
      </Helmet>
      <Navbar styles="bg-[#fff]" />
      <div className="w-full bg-[#f5f5f5]">
        <h1 className="md:text-center pl-[2rem] py-[4rem] text-[2rem] md:text-[3.2rem] font-bold">
          Privacy Policy for Xampus
        </h1>
      </div>
      <div className="flex justify-center">
        <div className="privacy-content w-[90%] md:w-[70%]">
          <div className="privacy-head">
            <p className="mt-8">
              At Xampus, accessible from www.xampus.in, one of our main
              priorities is the privacy of our visitors. This Privacy Policy
              document contains types of information that is collected and
              recorded by Xampus and how we use it.
              <br />
              If you have additional questions or require more information about
              our Privacy Policy, do not hesitate to contact us.
            </p>
          </div>

          <div className="privacy-body mb-[5rem]">
            <div className="section-1">
              <h3>Log Files</h3>
              <p>
                Xampus follows a standard procedure of using log files. These
                files log visitors when they visit websites. All hosting
                companies do this and a part of hosting services' analytics. The
                information collected by log files include internet protocol
                (IP) addresses, browser type, Internet Service Provider (ISP),
                date and time stamp, referring/exit pages, and possibly the
                number of clicks. These are not linked to any information that
                is personally identifiable. The purpose of the information is
                for analyzing trends, administering the site, tracking users'
                movement on the website, and gathering demographic information.
                Our Privacy Policy was created with the help of the Privacy
                Policy Generator.
              </p>
            </div>

            <div className="section-2">
              <h3>Privacy Policies</h3>
              <p>
                You may consult this list to find the Privacy Policy for each of
                the advertising partners of Xampus.
                <br />
                Third-party ad servers or ad networks uses technologies like
                cookies, JavaScript, or Web Beacons that are used in their
                respective advertisements and links that appear on Xampus, which
                are sent directly to users' browser. They automatically receive
                your IP address when this occurs. These technologies are used to
                measure the effectiveness of their advertising campaigns and/or
                to personalize the advertising content that you see on websites
                that you visit.
                <br />
                Note that Xampus has no access to or control over these cookies
                that are used by third-party advertisers.
              </p>
            </div>

            <div className="section-3">
              <h3>Third Party Privacy Policies</h3>
              <p>
                Xampus's Privacy Policy does not apply to other advertisers or
                websites. Thus, we are advising you to consult the respective
                Privacy Policies of these third-party ad servers for more
                detailed information. It may include their practices and
                instructions about how to opt-out of certain options.
                <br />
                You can choose to disable cookies through your individual
                browser options. To know more detailed information about cookie
                management with specific web browsers, it can be found at the
                browsers' respective websites. What Are Cookies?
              </p>
            </div>

            <div className="section-4">
              <h3>Children's Information</h3>
              <p>
                Another part of our priority is adding protection for children
                while using the internet. We encourage parents and guardians to
                observe, participate in, and/or monitor and guide their online
                activity.
                <br />
                Xampus does not knowingly collect any Personal Identifiable
                Information from children under the age of 13. If you think that
                your child provided this kind of information on our website, we
                strongly encourage you to contact us immediately and we will do
                our best efforts to promptly remove such information from our
                records.
              </p>
            </div>

            <div className="section-5">
              <h3>Online Privacy Policy Only</h3>
              <p>
                Online Privacy Policy Only This Privacy Policy applies only to
                our online activities and is valid for visitors to our website
                with regards to the information that they shared and/or collect
                in Xampus. This policy is not applicable to any information
                collected offline or via channels other than this website.
              </p>
            </div>

            <div className="section-6">
              <h3>Consent</h3>
              <p>
                By using our website, you hereby consent to our Privacy Policy
                and agree to its Terms and Conditions.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer/>
    </>
  );
};

export default Privacy_Policy;
