import { DisposableEmailDomains } from './TempEmailDomains';

// Function to validate an email address using a regular expression
export const isValidEmail = (email: string): boolean => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
};

// Function to validate a mobile number (10 digits for example)
export const isValidMobileNumber = (mobileNumber: string): boolean => {
    const mobileNumberRegex = /^[0-9]{10}$/;
    return mobileNumberRegex.test(mobileNumber);
};

// Function to validate a password based on length (e.g., at least 8 characters)
export const isValidPassword = (password: string): boolean => {
    return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\W_])(?=.{8,})/.test(password);
};

// Function to check if the password contains at least one number
export const isPasswordContainNumber = (password: string): boolean => {
    const numberRegex = /[0-9]/;
    return numberRegex.test(password);
};

// Function to check if the password contains at least one special character
export const isPasswordContainSpecialChar = (password: string): boolean => {
    return /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(
        password,
      );
};

// Function to check if the password contains at least one capital letter
export const isPasswordContainCapitalChar = (password: string): boolean => {
    const capitalCharRegex = /[A-Z]/;
    return capitalCharRegex.test(password);
};

export const getBackgroundColor = (alphabet:string = 'A') => {
    const coolLightShadeColors = [
      '#37474F', // Dark Blue Grey
      '#263238', // Dark Blue Grey (darker)
      '#1B5E20', // Dark Green
      '#004D40', // Dark Teal
      '#0D47A1', // Dark Blue
      '#3E2723', // Dark Brown
      '#311B92', // Dark Indigo
      '#4A148C', // Dark Purple
      '#880E4F', // Dark Pink
      '#BF360C', // Dark Orange
    ];
    const index = alphabet?.toUpperCase()?.charCodeAt(0) % coolLightShadeColors?.length;
    return coolLightShadeColors[index];
};

export const isDisposableEmail = (email:string='') => {
    const domain = email?.substring(email?.lastIndexOf("@") + 1);
    return DisposableEmailDomains?.includes(domain);
  };

  export const LANGUAGES = [
    "English",
    "Spanish",
    "French",
    "German",
    "Chinese",
    "Japanese",
    "Korean",
    "Russian",
    "Portuguese",
    "Italian",
  ];