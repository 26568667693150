import React from "react";
import bgjobHunt from "../assets/imgs/bg-jobhunt.png";
import logo from "../assets/imgs/xlogoBlue.png";
import { FaGooglePlay } from "react-icons/fa";
import { GrApple } from "react-icons/gr";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

const JobHunt = () => {
  const handleIosClick = () => {
    toast.warn(
      <div>
        <strong>Coming soon!</strong>
        <div>
          The iOS version of our app will be available shortly. Stay tuned!
        </div>
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <title>Job Hunt Secrets: Grab Your Dream Career Fast</title>
        <meta name="description" content="Discover fascinating career opportunities that await you. From unique roles to powerful insights, learn how to identify your best path and elevate your professional journey. Start your adventure today and find your passion!" />
        <meta name="keywords" content="Job hunt, Xampus, Company" />
      </Helmet>
      <div
        className="min-h-screen bg-cover bg-center bg-no-repeat"
        style={{ backgroundImage: `url(${bgjobHunt})` }}
      >
        <div className="flex flex-col pl-[1rem] md:pl-[4rem] pr-4 lg:pl-[8rem] text-white pt-[5rem] gap-10">
          <img src={logo} alt="" className="h-[5rem] w-[5rem] shadow-md" />
          <div>
            <h1 className="text-2xl md:text-[40px] font-semibold">
              Discover More Opportunities with Our App!
            </h1>
            <p className="text-[18px] md:text-[24px] text-[#B4D6FF] w-full lg:w-[1078px] leading-snug">
              Thank you for visiting our job listing. Did you know that millions
              of similar job opportunities are available right at your
              fingertips? Our app is designed to connect you with countless job
              openings tailored to your skills and interest.
            </p>
          </div>
          <div>
            <h1 className="text-2xl md:text-[40px] mb-5 font-semibold">
              Why download our app?
            </h1>
            <ul className="text-[18px] list-disc pl-5 md:text-[24px] text-[#B4D6FF] w-full lg:w-[1078px] leading-snug">
              <li>
                Vast Job Listings: Access millions of job postings from various
                industries.
              </li>
              <li>
                Personalized Recommendations: Get job suggestions that match
                your profile.
              </li>
              <li>
                Seamless Application Process: Apply to jobs directly through the
                app with ease.
              </li>
              <li>
                Stay Updated: Receive real-time notifications for new job
                openings and application updates.
              </li>
            </ul>
          </div>
          <div>
            <div className="flex gap-2 md:gap-6 flex-wrap py-4 md:py-6">
              {/* <a
                href="https://play.google.com/store/apps/details?id=com.xampus.aiapp&hl=en-IN"
                target="blank"
              > */}
                <div className="jobhunt-android border-[#8C8A9399] bg-[#060F40]">
                  <FaGooglePlay className="w-[35px] h-[55px] md:w-[52px] md:h-[73px] lg:w-[72px] lg:h-[93px]" />
                  <div>
                    <p className="text-[13px] md:text-[16px] lg:text-[20px] leading-3 md:leading-none">
                      GET IT ON
                    </p>
                    <p className="text-[1.2rem] md:text-[30px] lg:text-[40px] font-normal pb-2 leading-3 md:leading-none">
                      Play Store
                    </p>
                  </div>
                </div>
              {/* </a> */}

              <div
                className="jobhunt-ios border-[#8C8A9399] bg-[#060F40]"
                onClick={handleIosClick}
              >
                <GrApple className="w-[38px] h-[58px] md:w-[52px] md:h-[73px] lg:w-[72px] lg:h-[93px]" />
                <div className="">
                  <p className="text-[13px] md:text-[16px] lg:text-[20px] leading-3 md:leading-none">
                    GET IT ON
                  </p>
                  <p className="text-[1.2rem] md:text-[30px] lg:text-[40px] font-normal pb-2 leading-3 md:leading-none">
                    App Store
                  </p>
                </div>
              </div>
            </div>
            <p className="text-[18px] md:text-[24px] text-[#B4D6FF] pb-4">
              Don’t miss out on your dream job. Download our app now and take
              the next step in your career journey!
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobHunt;
